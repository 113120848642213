import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import COLORS from '../constants/colors'
import FONTS from '../constants/fonts'
import { useIntl, navigate } from 'gatsby-plugin-intl'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby-plugin-intl'
import NAV_ITEMS from '../constants/nav-items'
import logoGreen from '../images/green-metal-logo.svg'

const useStyles = makeStyles(theme => ({
  Footer: {
    backgroundColor: COLORS.BLACK,
    color: `${COLORS.WHITE} !important`,
    width: '100%',
    margin: 0,
    height: 'fit-content',
    padding: '90px 60px 90px 60px ',
    '& a,p': { 'line-height': 1.4 },
    '& a': {
      width: 'fit-content',
      textDecoration: 'none',
      display: 'inline-block',
      '&:hover': {
        color: `${COLORS.GREEN}`, //TODO: check color we should use here
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '40px 35px 40px',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      paddingTop: 50,
    },
    [theme.breakpoints.between('sm', 825)]: {
      fontSize: FONTS.SIZE.XXXS,
    },
  },
  address: {
    width: 150,
  },
  companyInformation: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  email: {
    color: COLORS.GREEN,
    display: 'flex',
    flex: 1,
    pointerEvents: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '20vw !important',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  pagesColumn: {
    display: 'flex',
    fontWeight: FONTS.WEIGHT.MEDIUM,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: { marginBottom: 20 },
    [theme.breakpoints.between('sm', 825)]: {
      fontSize: FONTS.SIZE.XXXS,
    },

    '& >*': {
      marginBottom: 8,
    },
  },
  link: {
    color: COLORS.WHITE_TEXT,
    paddingBottom: '24px',
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.M,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden,',
  },
  copyRights: {
    fontSize: FONTS.SIZE.XXXS,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    alignSelf: 'flex-end',
  },
  addresInformation: {
    marginBlockStart: 0,
    fontWeight: FONTS.WEIGHT.MEDIUM,
  },
  logo: {
    width: 180,
    [theme.breakpoints.down('md')]: {
      width: 120,
    },
  },
  button: {
    padding: '0px !important',
  },
}))

const Footer = () => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulGreenMetalsFooter {
        edges {
          node {
            address
            copyrights
            organisationNumber
            email
            businessContact
            businessContactRole
            phoneNumber
            email2
            businessContact2
            businessContactRole2
            phoneNumber2
          }
        }
      }
    }
  `)
  const footerData = data.allContentfulGreenMetalsFooter.edges[0].node
  return (
    <Grid className={classes.Footer} container spacing={4}>
      <Grid item xs={12} md={3}>
        <Grid container className={classes.companyInformation}>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              onClick={() => navigate(NAV_ITEMS[0].link)}
            >
              {<img src={logoGreen} className={classes.logo} alt="Vow logo" />}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container>
          <Grid item xs={12}>
            <h3 className={classes.addresInformation}> Company Address</h3>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.address}> {footerData.address}</p>
          </Grid>
          <Grid item xs={12}>
            <p> {footerData.organisationNumber}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container>
          <Grid item xs={12}>
            <h3 className={classes.addresInformation}> Business Inquiries</h3>
          </Grid>
          <Grid item xs={12}>
            <p>
              {footerData.businessContact} {<br />}
              {footerData.businessContactRole} {<br />}
              {footerData.phoneNumber} {<br />}
              {
                <a href={footerData.email} className={classes.email}>
                  {' '}
                  {footerData.email}
                </a>
              }
            </p>
            {footerData.businessContact2 && (
              <p>
                {footerData.businessContact2} {<br />}
                {footerData.businessContactRole2} {<br />}
                {footerData.phoneNumber2} {<br />}
                {
                  <a href={footerData.email2} className={classes.email}>
                    {' '}
                    {footerData.email2}
                  </a>
                }
              </p>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container className={classes.pagesColumn}>
          {NAV_ITEMS.map(item => (
            <Grid item xs={12} key={item.link}>
              <Link className={classes.link} to={item.link}>
                {formatMessage({ id: item.intlId })}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.copyRights}>
        &copy; {footerData.copyrights}
      </Grid>
    </Grid>
  )
}

export default Footer
